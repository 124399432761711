//send repair data back to Monday board
import {reqGetRepairForMonday} from './index';
import {message} from 'antd';
import {formatDate, saleErpIdToMondayId} from '../utils/utility';
import {MONDAY} from '../utils/const';

export const sendRepairToMonday = (repair_id) => {

  //add repair to monday
  //monday_board_id = 4387256784;
  //monday_group_id = "new_group43883";
  //Status             id: status2 - 153
  //Task Owner         id: person
  //Priority           id: status_18  High(red): "index":2,  default(grey): "index":5
  //Received Date      id: date2 (order date)
  //Due date           id: date4
  //Job Type           id: text_Mjj7yZO0
  //Item Desc          id: text_Mjj7vflT
  //Repair Desc        id:text0

  reqGetRepairForMonday(repair_id)
    .then(response => {
      let repair = response.data[0];
      console.log(repair);
      let full_name = repair.first_name + " " + repair.last_name;
      let monday_board_id = 4387256784;
      let monday_group_id = "new_group43883"
      let query = `mutation { create_item (board_id: ${monday_board_id} , group_id: \"${monday_group_id}\", item_name: \"${full_name}\") { id }}`;
      fetch("https://api.monday.com/v2", {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': MONDAY
        },
        body: JSON.stringify({
          query: query
        })
      })
        .then(res => res.json())
        .then(res => {
          if (!!res.data) {
            let id = res.data.create_item.id;
            let boardId = 4387256784;
            let itemId = parseInt(id);
            //Priority: RUSH(red): "index":2,  default(grey): "index":5
            let monday_priority = 5;
            if (repair.urgent) {
              monday_priority = 2;
            }
            console.log(repair.sales_representitive)
            let task_owner_id = saleErpIdToMondayId(repair.sales_representitive);
            console.log(task_owner_id)
            
            let model = {
              status2: {index: 153},
              person: {personsAndTeams: [{id: task_owner_id, kind: 'person'}]},
              status_18: {index: monday_priority},
              date2: {date: formatDate(repair.order_date)},
              text_Mjj7yZO0: repair.job_type,
              text_Mjj7vflT: repair.item_description,
              text0: repair.repair_description,
              link8: {
                url: 'https://erp.engagestudio.com/#/orders/edit/' + repair.order_id,
                text: 'ERP - ' + full_name
              },
              date4: {date: formatDate(repair.due_date)},
            };

            let query = `mutation {change_multiple_column_values (
                              board_id: ${boardId}, item_id: ${itemId},column_values: ${JSON.stringify(JSON.stringify(model))}) {id}}`;


            fetch("https://api.monday.com/v2", {
              method: 'post',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': MONDAY
              },
              body: JSON.stringify({
                query: query,
              })
            })
              .then((responseJson) => {
                message.success('Added to Monday.com!');
              })
              .catch((error) => {
                console.log(error)
                message.error('Cannot add to Monday.com');
              });
          } else {
            message.error('Cannot add to Monday.com');
          }
        });

    })
}