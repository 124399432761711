import React, {Component, Fragment} from "react";
import {message, Modal, Icon, Spin} from "antd";
import {
  reqGetOrderItemsByTypeId, reqAddOrderItemByType, reqUpdateExcludeByOrderItemId,
  reqUpdateOrderItemIdByType, reqDeletePo, reqDeleteOrderItem
} from '../../api';
import ItemTable from './ItemTable';
import {momentToString} from '../../utils/utility';
import LinkButton from '../LinkButton';
import DiamondAddUpdateForm from '../Order/Diamond/DiamondAddUpdateForm';
import JewelleryAddUpdateForm from '../Order/Jewellery/JewelleryAddUpdateForm';
import ComponentsAddUpdateForm from '../Order/Component/ComponentsAddUpdateForm';
import RepairsAddUpdateForm from '../Order/Repairs/RepairsAddUpdateForm';
import StonesAddUpdateForm from '../Order/Stone/StonesAddUpdateForm';
import StyleDetailsAddUpdateForm from '../Order/Style/StyleDetailsAddUpdateForm';
import ManufacturingInfo from '../Order/Style/ManufacturingInfo';
import {sendRepairToMonday} from '../../api/monday';

class TableComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      selectedRowKeys: [],
      addUpdateDialogShow: 0, //0:hide; 1:Add, 2:Update
      styleInfoDialogShow: false,
      styleInfo: "",
      flag: false,
      columnsBtn: []
    }
  }

  componentDidMount() {
    this.loadDada();
    this.columnsBtn();
    if (this.props.showAdd) {
      this.setState({addUpdateDialogShow: 1});
      this.order_item = {};
    }
  }

  //show add dialog
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.showAdd !== prevProps.showAdd) {
      console.log(this.props.showAdd)
      if (this.props.showAdd) {
        this.setState({addUpdateDialogShow: 1});
        this.order_item = {};
      }
    }
  }

  handleAddUpdate = () => {
    this.form.validateFields(async (err, values) => {
      if (!err) {
        console.log(values)
        //Must have Vendor and Status at same time
        const {vendor_id, status} = values;
        if (!(vendor_id) !== !(status)) {
          message.error("Must have Vendor and Status at same time");
          return;
        }

        //Must Select Requires Approval When Approved is Selected
        // const {requires_approval, approved} = values;
        // // eslint-disable-next-line
        // if ((requires_approval == false) && (approved == true)) {
        //   message.error("Must Select Requires Approval When Approved is Selected");
        //   return;
        // }

        //no error
        this.setState({loading: true});
        let order_item = values;
        console.log("order_item", order_item);
        order_item.order_id = this.props.id;
        if (!(this.order_item.order_item_id)) {
          //add
          reqAddOrderItemByType(this.props.type, order_item)
            .then(response => {
              this.setState({data: response.data, loading: false, addUpdateDialogShow: 0});
              message.success('Added Successfully!');

              //add repair to monday
              if(this.props.type === 'repairs'){
                let repair_id = response.data.slice(-1)[0].order_item_id;
                sendRepairToMonday(repair_id);
              }

              this.form.resetFields();
              this.props.onUpdateFee();
              //if order status is not Incomplete
              if (this.props.status !== 'Incomplete') {
                this.props.onUpdateOrderInfo();
              }
            })
            .catch(error => {
              console.log(error)
            });
        } else {
          //update
          if (values.start_date) {
            values.start_date = momentToString(values.start_date);
          }
          reqUpdateOrderItemIdByType(this.props.type, this.order_item.order_item_id, order_item)
            .then(response => {
              this.setState({data: response.data, loading: false, addUpdateDialogShow: 0});
              message.success('Updated Successfully!');
              this.form.resetFields();
              //if update retail, update fee list
              if (this.order_item.retail !== order_item.retail) {
                this.props.onUpdateFee();
              }
              //if status changed, change order info
              this.props.onUpdateOrderInfo();
            })
            .catch(error => {
              console.log(error)
            });
        }
      }
    })
  };

  //delete PO
  deletePO = () => {
    Modal.confirm({
      title: `Are you sure you want to delete Po# ${this.order_item.po_number}?`,
      onOk: () => {
        const order_item_id = this.order_item.order_item_id;
        reqDeletePo(order_item_id)
          .then(response => {
            this.setState((prevState) => ({
                data: prevState.data.map(item => {
                    if (item.order_item_id === order_item_id) {
                      item = {...item, po_number: "", name: "", vendor_id: "", status: "", start_date: ""}
                    }
                    return item
                  }
                )
              }
            ));
            this.setState({loading: false, addUpdateDialogShow: 0});
            this.props.onUpdateOrderInfo();
          })
          .catch(error => {
            console.log(error)
          });

      },
      onCancel() {
      },
    })
  };

  //Delete
  handleDelete = (order_item) => {
    Modal.confirm({
      title: "Are you sure you want to delete ?",
      onOk: () => {
        reqDeleteOrderItem(order_item.order_item_id)
          .then(response => {
            const afterDelete = this.state.data.filter(item => !(item.order_item_id === order_item.order_item_id));
            this.setState({data: afterDelete});
            message.success('Deleted Successfully');
            this.props.onUpdateFee();
            //update status
            this.props.onUpdateOrderInfo();
          })
          .catch(error => {
            console.log(error)
            message.error('Error ' + error.response.status + ": " + error.response.data.error);
          });
      },
      onCancel() {
      },
    })
  };

  //show add Dialog
  showAdd = () => {
    this.order_item = {};
    this.setState({addUpdateDialogShow: 1})
  };

  //show update Dialog
  showUpdate = (order_item) => {
    console.log(order_item)
    this.order_item = order_item;
    this.setState({addUpdateDialogShow: 2})
  };

  hideDialog = () => {
    this.setState({addUpdateDialogShow: 0});
  }

  loadDada = () => {
    reqGetOrderItemsByTypeId(this.props.type, this.props.id)
      .then(response => {
        const data = response.data;
        this.setState({data});
        //if item is exclude, checked
        let selectedRowKeys = [];
        for (let i = 0; i < data.length; i++) {
          if (!!data[i].exclude_from_invoice) {
            selectedRowKeys.push(data[i].id);
          }
        }
        this.setState({selectedRowKeys})
      })
      .catch(error => {
        console.log(error)
      });
  }

  //checkbox change
  onChange = (selectedRowKeys, selectedRows) => {
    this.setState({selectedRowKeys});
  };

  //CheckBox select changed
  onSelectChange = (selectedRow) => {
    if (this.state.flag) return false;
    this.setState({flag: true})
    reqUpdateExcludeByOrderItemId(selectedRow.order_item_id)
      .then(response => {
        if (response.data) {
          message.success('Excluded!');
        } else {
          message.success('Unexcluded!');
        }
        this.setState({flag: false})
      })
      .catch(error => {
        console.log(error)
        console.log(error.response);
        this.setState({flag: false})
      });
  };

  getCheckboxProps = record => ({
    // eslint-disable-next-line
    disabled: record.retail != 0, // Column configuration not to be checked
  });

  //add btn to columns
  columnsBtn = columns => {
    const columnsBtn = [...this.props.columns, {
      title: 'Action',
      key: 'action',
      render: (item) => (
        item.type === 'style' ?
          <span>
            <LinkButton onClick={() => this.showUpdate(item)}><Icon type="edit"/></LinkButton>
            <LinkButton onClick={() => this.handleDelete(item)}><Icon type="delete"/></LinkButton>
            <LinkButton onClick={() => this.getStyleInfo(item)}><Icon type="file-text"/></LinkButton>
          </span>
          :
          <span>
            <LinkButton onClick={() => this.showUpdate(item)}><Icon type="edit"/></LinkButton>
            <LinkButton onClick={() => this.handleDelete(item)}><Icon type="delete"/></LinkButton>
          </span>
      ),
    }];
    this.setState({columnsBtn})
  }

  //render Switch add update form
  renderSwitch(param) {
    const addUpdateForm = {
      order_item: this.order_item,
      setForm: form => this.form = form,
      deletePO: this.deletePO,
      hideDialog: this.hideDialog
    };
    switch (param) {
      case 'diamonds':
        return (<DiamondAddUpdateForm {...addUpdateForm}/>);
      case 'jewelleries':
        return (<JewelleryAddUpdateForm {...addUpdateForm}/>);
      case 'components':
        return (<ComponentsAddUpdateForm {...addUpdateForm}/>);
      case 'repairs':
        return (<RepairsAddUpdateForm {...addUpdateForm}/>);
      case 'stones':
        return (<StonesAddUpdateForm {...addUpdateForm}/>);
      case 'styles':
        return (<StyleDetailsAddUpdateForm {...addUpdateForm}/>);
      default:
    }
  }

  //style:get style info
  getStyleInfo = (order_item) => {
    this.setState({styleInfoDialogShow: true})
    this.order_item = order_item;

  };

  render() {
    const {loading, selectedRowKeys, data, addUpdateDialogShow, columnsBtn, styleInfoDialogShow} = this.state;
    const {type} = this.props;
    const rowSelection = {
      columnTitle: ' ',
      selectedRowKeys,
      onChange: this.onChange,
      getCheckboxProps: this.getCheckboxProps,
      onSelect: this.onSelectChange
    };
    const add_type = () => {
      let title;
      if (this.props.job_description === 'Repair') {
        if (type === 'repairs') {
          title = <LinkButton onClick={this.showAdd}><span>{`Add ${type}`}</span></LinkButton>;
        } else if (type === 'components') {
          title = <LinkButton onClick={this.showAdd}><span>{`Add ${type}`}</span></LinkButton>;
        } else {
          title = <LinkButton></LinkButton>
        }
      } else {
        title = type !== 'repairs' ? <LinkButton onClick={this.showAdd}><span>{`Add ${type}`}</span></LinkButton> :
          <LinkButton></LinkButton>;
      }
      return title;
    }

    return (
      <Fragment>
        <div style={{float: 'right', position: 'relative', zIndex: 99, marginTop: '-8px', marginBottom: '6px'}}>
          {add_type()}
        </div>

        <ItemTable
          columns={columnsBtn}
          dataSource={data}
          rowSelection={rowSelection}
        />
        <Modal
          width={800}
          title={addUpdateDialogShow === 1 ? `Add ${type}` : `Update ${type}`}
          visible={addUpdateDialogShow !== 0}
          onOk={this.handleAddUpdate}
          onCancel={() => {
            this.form.resetFields();
            this.setState({addUpdateDialogShow: 0})
          }}
        >
          <Spin spinning={loading}>
            {this.renderSwitch(this.props.type)}
          </Spin>
        </Modal>
        <Modal
          width={800}
          visible={styleInfoDialogShow}
          onCancel={() => this.setState({styleInfoDialogShow: false})}
        >
          <ManufacturingInfo style={this.order_item}/>
        </Modal>
      </Fragment>
    )
  }
}

export default TableComponent;