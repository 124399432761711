import React, {Component} from 'react';
import {withRouter} from "react-router";
import {Form, Select, Checkbox, Modal, Button, DatePicker, message, Spin} from 'antd';
import moment from 'moment';
import {ORDER_JOB_TYPE} from "../../utils/const";
import {reqAddOrder, reqGetAddressOrderId, reqGetSales} from "../../api";
import {formatDate, momentToString, saleErpIdToMondayId} from "../../utils/utility";
import {MONDAY} from '../../utils/const';

const {Item} = Form;
const {Option} = Select;

class OrderAddForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showAddDialog: false,
      sales: [],
      loading: false
    };
  }

  onAdd = () => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({loading: true});
        let {order_date, due_date, picked_up} = values;
        order_date = momentToString(order_date);
        due_date = momentToString(due_date);
        picked_up = momentToString(picked_up);
        const {sales_representitive, payment_type, urgent, job_description} = values;
        const customer_id = this.props.id;
        const order = {
          sales_representitive, payment_type, urgent,
          job_description, order_date, due_date, picked_up, customer_id
        };

        reqAddOrder(order)
          .then(response => {
            message.success('Added successfully!');
            this.setState({loading: false});
            const order_id = response.data.id;
            this.props.history.replace('/orders/edit/' + order_id);
            let full_name = this.props.customer.first_name + " " + this.props.customer.last_name;
            let monday_board_id, monday_group_id, monday_status, task_owner_id, sales_id, postal_code
            if (job_description === "Ring Registry") {
              //Ring board - New Ring Registry
              monday_board_id = 1551353287;
              monday_group_id = "new_group35083";
              monday_status = 160;
              //Cindy
              task_owner_id = 24462877;
              sales_id = saleErpIdToMondayId(sales_representitive);
            } else if (job_description === "Repair") {
              //skip
            } else {
              //Rings board - New Order
              monday_board_id = 1551353287;
              monday_group_id = "new_group47309";
              monday_status = 6;
              task_owner_id = sales_id = saleErpIdToMondayId(sales_representitive);
              //get postal code
              reqGetAddressOrderId(order_id)
                .then(response => {
                  const address = response.data;
                  postal_code = address.postal_code;
                })
                .catch(error => {
                  console.log(error.response)
                });
            }
            //create task in the board
            // eslint-disable-next-line
            let query = `mutation { create_item (board_id: ${monday_board_id} , group_id: \"${monday_group_id}\", item_name: \"${full_name}\") { id }}`;
            fetch("https://api.monday.com/v2", {
              method: 'post',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': MONDAY
              },
              body: JSON.stringify({
                query: query
              })
            })
              .then(res => res.json())
              .then(res => {
                  if (!!res.data) {
                    message.success('Added to Monday.com!');
                    let id = res.data.create_item.id;
                    //Priority: RUSH(red): "index":2,  default(grey): "index":5
                    let monday_priority = 5;
                    if (urgent) {
                      monday_priority = 2;
                    }
                    if(job_description !== "Repair") {
                      //Start Date         id: date2
                      //Pick Up Date       id: date
                      //Status             id: status2  [new order]: "index":6 [new ring registry]:160
                      //Task Owner         id: person   Ji:23814627  Mariam:23982991
                      //Sales              id: people
                      //Priority           id: status_18  High(red): "index":2,  default(grey): "index":5
                      //Job Description    id:dropdown
                      //Link               id:link
                      let boardId = monday_board_id;
                      let itemId = parseInt(id);
                      let model = {
                        status2: {index: monday_status},
                        date2: {date: formatDate(order_date)},
                        date: {date: formatDate(due_date)},
                        status_18: {index: monday_priority},
                        dropdown: {labels: [job_description]},
                        postal_code: postal_code,
                        person: {personsAndTeams: [{id: task_owner_id, kind: "person"}]},
                        link: {
                          url: 'https://erp.engagestudio.com/#/orders/edit/' + order_id,
                          text: 'ERP - ' + full_name
                        },
                        people: {personsAndTeams: [{id: sales_id, kind: "person"}]}
                      };

                      let query = `mutation {change_multiple_column_values (
                      board_id: ${boardId}, item_id: ${itemId},column_values: ${JSON.stringify(JSON.stringify(model))}) {id}}`;

                      fetch("https://api.monday.com/v2", {
                        method: 'post',
                        headers: {
                          'Content-Type': 'application/json',
                          'Authorization': MONDAY
                        },
                        body: JSON.stringify({
                          query: query,
                        })
                      })
                        .then(res => res.json())
                        .then(data => console.log(data))
                    }
                  }
                }
              );
          })
          .catch(error => {
            console.log(error)
          });
      }
    })
  };

  handleClick = () => {
    this.ajaxGetSales();
    this.setState({showAddDialog: true})
  };

  ajaxGetSales = () => {
    //get all sales
    reqGetSales()
      .then(response => {
        const sales = response.data;
        this.setState({sales});
      })
      .catch(error => {
        console.log(error.response)
      });
  };

  disabledDate = (current) => {
    // Can not select days before today
    return current < moment().subtract(1, 'day');
  };

  render() {
    const {showAddDialog, loading} = this.state;
    const {getFieldDecorator} = this.props.form;
    const formLayout = {
      labelCol: {span: 7},
      wrapperCol: {span: 11}
    };

    return (
      <React.Fragment>
        <Button style={{margin: '0px 5px'}} type='primary' onClick={() => this.handleClick()}>
          New Order
        </Button>
        <Modal
          title='Add Order'
          visible={showAddDialog}
          onOk={this.onAdd}
          onCancel={() => {
            this.setState({showAddDialog: false});
            this.props.form.resetFields();
          }}
        >
          <Spin spinning={loading}>
            <Form {...formLayout}>

              <Item label="Date">
                {
                  getFieldDecorator('order_date', {
                    initialValue: moment(),
                    rules: [
                      {required: true, message: 'Date is required'}
                    ]
                  })(<DatePicker/>)
                }
              </Item>

              <Item label="Due Date">
                {
                  getFieldDecorator('due_date', {
                    initialValue: null,
                    rules: [
                      {required: true, message: 'Due Date is required'}
                    ]
                  })(<DatePicker disabledDate={this.disabledDate}/>)
                }
              </Item>

              <Item label='Sales'>
                {
                  getFieldDecorator('sales_representitive', {
                    initialValue: '',
                    rules: [
                      {required: true, message: 'Sales is required'}
                    ]
                  })(
                    <Select
                      showSearch
                      filterOption={(inputValue, option) =>
                        option.props.children
                          .toString()
                          .toLowerCase()
                          .includes(inputValue.toLowerCase())
                      }
                    >
                      {
                        this.state.sales.map(item => <Option value={item.id} key={item.id}> {item.full_name} </Option>)
                      }
                    </Select>)}
              </Item>

              <Item label="Payment Type">
                {getFieldDecorator('payment_type', {
                  initialValue: 'cad',
                  rules: [
                    {required: true, message: 'Type is required'}
                  ]
                })(
                  <Select>
                    <Option value="cad">CAD</Option>
                    <Option value="usd">USD</Option>
                  </Select>
                )}
              </Item>

              <Item label="Job Description">
                {getFieldDecorator('job_description', {
                  initialValue: '',
                  rules: [
                    {required: true, message: 'Job Description is required'}
                  ]
                })(
                  <Select>
                    {
                      ORDER_JOB_TYPE.map(item => <Option value={item} key={item}> {item} </Option>)
                    }
                  </Select>
                )}
              </Item>

              <Item label="Urgent">
                {getFieldDecorator('urgent', {
                  initialValue: 0,
                  valuePropName: 'checked',
                })(
                  <Checkbox/>
                )}
              </Item>
            </Form>
          </Spin>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Form.create()(withRouter(OrderAddForm))
